<script>
import { mapGetters } from 'vuex'
import Nprogress from 'nprogress'
import { createToast } from 'mosha-vue-toastify'

const progress1 = Nprogress.configure({
  parent: '.nprogress-container',
  trickleSpeed: 50,
  showSpinner: true,
})

export default {
  components: {},
  computed: {
    ...mapGetters(['merchant', 'accounts']),
  },
  data() {
    return {
      model: {},
      loading: false,
      error: null,
      dropdownToggle: false,
    }
  },
  methods: {
    submit() {
      if (this.$route.params.id === 'new') this.create()
      if (this.$route.params.id !== 'new') this.update()
    },
    async update() {
      if (!this.loading) {
        this.loading = true
        this.error = null
        progress1.start()
        await this.$store
          .dispatch('updateMerchant', this.model)
          .then((res) => {
            setTimeout(() => {
              console.log(res)
              this.loading = false
              progress1.done()
              this.toast('Updated successfully.', {
                type: 'success',
                position: 'bottom-right',
                timeout: 5000,
              })
            }, 420)
          })
          .catch((e) => {
            console.log(e.data)
            if (e.data) {
              setTimeout(() => {
                this.loading = false
                const message =
                  e.data.message === 'jwt expired'
                    ? 'Your session has expired. Login to continue.'
                    : e.data.message
                this.toast(message, {
                  type: 'danger',
                  position: 'bottom-right',
                  timeout: 5000,
                })
                progress1.done()
              }, 420)
            }
          })
        progress1.done()
      }
    },
    async create() {
      if (!this.loading) {
        this.loading = true
        this.error = null
        progress1.start()

        await this.$store
          .dispatch('createAccount', this.model)
          .then((res) => {
            setTimeout(() => {
              console.log(res)
              this.loading = false
              progress1.done()
              this.$router.push('/merchants')
              this.toast('Succesfully added an account', {
                type: 'success',
                position: 'bottom-right',
                timeout: 5000,
              })
            }, 420)
          })
          .catch((e) => {
            console.log(e.data)
            if (e.data) {
              setTimeout(() => {
                this.loading = false
                const message =
                  e.data.message === 'jwt expired'
                    ? 'Your session has expired. Login to continue.'
                    : e.data.message
                this.toast(message, {
                  type: 'danger',
                  position: 'bottom-right',
                  timeout: 5000,
                })
                progress1.done()
              }, 420)
            }
          })
      }
    },
    toast(msg, options) {
      return createToast(msg, options)
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
  async mounted() {
    try {
      progress1.done()
      const id = this.$route.params.id

      if (id !== 'new') {
        const merchant = await this.$store.dispatch('getMerchant', { id })
        this.model = merchant

        await this.$store.dispatch('getAccounts', {
          limit: 1000,
          skip: 0,
          order: -1,
        })
      }

      /**
       *
       *
       */
    } catch (e) {
      /**
       *
       * uncaught exceptions
       *
       */
      console.log('UNCAUGHT PROMISE: ', e)
      const message =
        e.data.message === 'jwt expired'
          ? 'Your session has expired. Login to continue.'
          : e.data.message
      this.toast(message, {
        type: 'danger',
        position: 'bottom-right',
        timeout: 5000,
      })
    }
  },
}
</script>
<template>
  <div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <div class="card shadow bg-secondary nprogress-container">
            <div class="card-header">
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Merchant Details</h3>
                  </div>
                  <div class="col-4 text-right">
                    <router-link to="/merchants" class="active btn btn-sm btn-primary">
                      Show all
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <form role="form" name="form" @submit.prevent="submit" validate>
                <h6 class="heading-small text-muted mb-4">
                  Account information
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">Merchant Name</label>
                        <input alternative="" placeholder="company name" class="form-control form-control-alternative"
                          aria-describedby="addon-right addon-left" v-model="model.name" required type="text" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">Status</label>
                        <select class="form-control form-control-alternative" v-model="model.status">
                          <option value="verified">Verified</option>
                          <option value="pending">Pending</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">Account</label>
                        <select v-if="accounts && accounts.data && model.user"
                          class="form-control form-control-alternative" v-model="model.user.id">
                          <option v-for="item of accounts.data" :value="item.id" :key="item.id"
                            :disabled="item.moneybeesMerchantId">
                            {{ item.name }} ({{ item.email }})
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <button type="submit" class="btn btn-warning" :disabled="loading"
                          v-if="this.$route.params.id !== 'new'">
                          Update
                        </button>
                        <button type="submit" class="btn btn-success" :disabled="loading"
                          v-if="this.$route.params.id === 'new'">
                          Create
                        </button>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <base-alert type="danger" :dismissible="true" v-if="error">{{ error }}</base-alert>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<style>

</style>
