<template>
  <div>
    <base-header
      class="header pb-8 pt-8 pt-lg-8 d-flex align-items-center"
      style="overflow: hidden"
    >
      <video
        playsinline
        autoplay
        muted
        loop
        id="myVideo"
        style="position: absolute; left: 0; bottom: 0; width: 100%"
      >
        <source src="/img/video.mp4" type="video/mp4" />
      </video>

      <!-- Mask -->
      <span class="mask bg-gradient-warning opacity-8"></span>
      <!-- Header container -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1"></div>
      </div>
    </div>
    <div class="container-fluid mt-5">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'credentials',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['account']),
  },
  methods: {},
  async mounted() {},
}
</script>
<style></style>
