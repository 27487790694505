<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-md-8 d-flex align-items-center"
      style="overflow: hidden"
    >
      <video
        playsinline
        autoplay
        muted
        loop
        id="myVideo"
        style="position: absolute; left: 0; bottom: 0; width: 100%"
      >
        <source src="/img/video.mp4" type="video/mp4" />
      </video>

      <!-- Mask -->
      <span class="mask bg-gradient-warning opacity-8"></span>
      <!-- Header container -->
    </base-header>

    <div class="container-fluid mt--7">
      <accounts-table
        v-if="accountData"
        :tableData="accountData"
        :title="'Latest Accounts'"
        :listRoute="'showAccount'"
        :buttonRoute="'accounts'"
        :buttonText="'View All'"
      ></accounts-table>
      <!--Tables-->
      <!-- <div class="row mt-5">
        <div class="col-xl-6">
          <span class="nprogress-container1">
            <deposits-table
              v-if="depositData"
              :tableData="depositData"
              :title="'Latest Deposits'"
              :buttonRoute="'deposits'"
              :buttonText="'View All'"
            ></deposits-table>
          </span>
        </div>
        <div class="col-xl-6">
          <withdrawals-table
            v-if="withdrawalData"
            :tableData="withdrawalData"
            :title="'Latest Withdrawals'"
            :buttonRoute="'withdrawals'"
            :buttonText="'View All'"
          ></withdrawals-table>
        </div>
      </div> -->
      <!--End tables-->
    </div>
  </div>
</template>
<script>
import AccountsTable from '../Tables/AccountsTable'
// import DepositsTable from '../Tables/DepositsTable'
// import WithdrawalsTable from '../Tables/WithdrawalsTable'
import { mapGetters } from 'vuex'
import { createToast } from 'mosha-vue-toastify'

export default {
  components: {
    AccountsTable,
    // DepositsTable,
    // WithdrawalsTable,
  },
  data() {
    return {
      accountData: [],
      depositData: [],
      withdrawalData: [],
    }
  },
  computed: {
    ...mapGetters(['account', 'accounts', 'deposits', 'withdrawals']),
  },
  watch: {
    accounts(val) {
      if (val) this.accountData = val.data
      return val
    },
    deposits(val) {
      if (val) this.depositData = val.data
      return val
    },
    withdrawals(val) {
      if (val) this.withdrawalData = val.data
      return val
    },
  },
  methods: {
    toast(msg, options) {
      return createToast(msg, options)
    },
  },
  async mounted() {
    try {
      this.accountData = []
      this.depositData = []
      this.withdrawalData = []

      await this.$store
        .dispatch('getAccounts', { limit: 5, order: -1 })
        .catch((e) => {
          const message =
            e.data && e.data.message === 'jwt expired'
              ? 'Your session has expired. Login to continue.'
              : e.message
          this.toast(message, {
            type: 'danger',
            position: 'bottom-right',
            timeout: 5000,
          })
        })
      // await this.$store
      //   .dispatch('getDeposits', { limit: 5, order: -1 })
      //   .catch((e) => {
      //     const message =
      //       e.data && e.data.message === 'jwt expired'
      //         ? 'Your session has expired. Login to continue.'
      //         : e.message
      //     this.toast(message, {
      //       type: 'danger',
      //       position: 'bottom-right',
      //       timeout: 5000,
      //     })
      //   })
      // await this.$store
      //   .dispatch('getWithdrawals', { limit: 5, order: -1 })
      //   .catch((e) => {
      //     const message =
      //       e.data && e.data.message === 'jwt expired'
      //         ? 'Your session has expired. Login to continue.'
      //         : e.message
      //     this.toast(message, {
      //       type: 'danger',
      //       position: 'bottom-right',
      //       timeout: 5000,
      //     })
      //   })
      /**
       *
       *
       */
    } catch (e) {
      /**
       *
       * uncaught exceptions
       *
       */
      console.log('UNCAUGHT PROMISE: ', e)
      const message =
        e.data && e.data.message === 'jwt expired'
          ? 'Your session has expired. Login to continue.'
          : e.message
      this.toast(message, {
        type: 'danger',
        position: 'bottom-right',
        timeout: 5000,
      })
    }
  },
}
</script>
<style></style>
