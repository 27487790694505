<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" style="position: relative; min-height: 100vh">
    <side-bar :background-color="sidebarBackground" short-title="Wallet" title="Wallet">
      <template v-slot:links>
        <sidebar-item v-if="
          this.accountData && !this.accountData.permissions.includes('user')
        " :link="{
          name: 'Dashboard',
          icon: 'ni ni-tv-2 text-primary',
          path: '/dashboard',
        }" />
        <sidebar-item v-if="
          this.accountData && !this.accountData.permissions.includes('user')
        " :link="{
          name: 'Accounts',
          icon: 'ni ni-single-02 text-primary',
          path: '/accounts',
        }" />
        <!-- <sidebar-item
          :link="{
            name: 'Transactions',
            icon: 'ni ni-money-coins text-primary',
            path: '/deposits',
          }"
        /> -->
        <!-- <sidebar-item
          :link="{
            name: 'Sells',
            icon: 'ni ni-send text-primary',
            path: '/withdrawals',
          }"
        /> -->
        <sidebar-item v-if="
          this.accountData && !this.accountData.permissions.includes('user')
        " :link="{
          name: 'Rates Margin',
          icon: 'ni ni-money-coins text-primary',
          path: '/margin',
        }" />
        <sidebar-item v-if="
          this.accountData && !this.accountData.permissions.includes('user')
        " :link="{
          name: 'Merchants',
          icon: 'ni ni-briefcase-24 text-primary',
          path: '/merchants',
        }" />
        <sidebar-item v-if="
          this.accountData && !this.accountData.permissions.includes('user')
        " :link="{
          name: 'Branches',
          icon: 'ni ni-shop text-primary',
          path: '/branches',
        }" />
        <!-- <sidebar-item
          :link="{
            name: 'Wallets',
            icon: 'ni ni-folder-17 text-primary',
            path: '/wallets',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Addresses',
            icon: 'fa fa-book text-primary',
            path: '/addresses',
          }"
        /> -->
        <sidebar-item v-if="
          this.accountData && !this.accountData.permissions.includes('admin')
        " :link="{
          name: 'Credentials',
          icon: 'fa fa-key text-primary',
          path: '/credentials',
        }" />
        <sidebar-item v-if="
          this.accountData &&
          this.accountData.accountType === 'merchant' &&
          this.accountData.allowSubmerchant
        " :link="{
          name: 'Sub-Merchants',
          icon: 'ni ni-single-02 text-primary',
          path: '/submerchants',
        }" />
        <sidebar-item v-if="
          (this.accountData &&
            this.accountData.accountType === 'merchant' &&
            this.accountData.allowSubuser) ||
          (this.accountData &&
            this.accountData.accountType === 'submerchant' &&
            this.accountData.allowSubuser)
        " :link="{
          name: 'Sub-Users',
          icon: 'ni ni-single-02 text-primary',
          path: '/subusers',
        }" />
        <sidebar-item :link="{
          name: 'Settings',
          icon: 'ni ni-settings text-primary',
          path: '/profile',
        }" />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  computed: {
    ...mapGetters(['account']),
    accountData() {
      return this.account
    },
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
  },
}
</script>
<style lang="scss">

</style>
