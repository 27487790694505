import axios from 'axios'
const apiURL = process.env.VUE_APP_API_URL

export default {
  state: {
    wallet: null,
    wallets: [],
  },
  mutations: {
    SET_WALLET(state, data) {
      state.wallet = data
    },
    SET_WALLETS(state, data) {
      state.wallets = data
    },
  },
  actions: {
    async getWallets({ commit }, options) {
      return await axios
        .get(
          `${apiURL}/wallets?$limit=${
            options.limit ? options.limit : 10
          }&$sort[id]=${options.sort ? options.sort : -1}&$skip=${
            options.skip ? options.skip : 0
          }`
        )
        .then((wallets) => commit('SET_WALLETS', wallets.data))
    },
    async getWallet({ commit }, options) {
      return await axios
        .get(`${apiURL}/wallets/${options.id}`)
        .then((wallet) => commit('SET_WALLET', wallet.data))
    },
    async updateWallet({ commit }, options) {
      return await axios
        .put(`${apiURL}/wallets/${options.id}`, {
          amount: options.amount,
          accountId: options.accountId,
        })
        .then((wallet) => commit('SET_WALLET', wallet.data))
    },
    async createWallet({ commit }, options) {
      return await axios
        .post(`${apiURL}/wallets`, options)
        .then((wallet) => commit('SET_WALLET', wallet.data))
    },
  },
  getters: {
    wallet(state) {
      try {
        return state.wallet
      } catch (e) {
        console.log('Error getting wallet state', e)
      }
    },
    wallets(state) {
      try {
        return state.wallets
      } catch (e) {
        console.log('Error getting wallets', e)
      }
    },
  },
}
