<template>
  <div class="row">
    <div class="col-xl-12 order-xl-1">
      <card shadow type="secondary" class="nprogress-container">
        <template v-slot:header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">Credentials</h3>
              </div>
            </div>
          </div>
        </template>

        <form>
          <div class="" v-if="this.model">
            <h6 class="heading-small text-muted mb-4">Credentials</h6>

            <div class="col-lg-12 mb-5">
              <div
                class="alert alert-grey text-center"
                v-if="!this.model.credentials.length"
              >
                You have no active API Keys
              </div>
              <table
                class="table tablesorter"
                v-if="this.model.credentials.length"
              >
                <thead>
                  <th>Label</th>
                  <th>Key</th>
                  <th>IP Address</th>
                  <th>Created</th>
                  <th>Active</th>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) of this.model.credentials"
                    :key="index"
                    @click="goToItem(item.id)"
                    class="hover-pointer"
                  >
                    <td>{{ item.name }}</td>
                    <td>{{ item.apiKey }}</td>
                    <td>{{ item.ipAddress }}</td>

                    <td class="text-success">
                      {{ moment(item.createdAt).fromNow() }}
                    </td>
                    <td
                      class="text-uppercase"
                      :class="item.active ? 'text-success' : 'text-danger'"
                    >
                      {{ item.active }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <button
                  class="btn btn-success"
                  :disabled="loading"
                  @click="goToItem('new')"
                >
                  Create API Key
                </button>
              </div>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Nprogress from 'nprogress'

export default {
  data() {
    return {
      loading: false,
      moment,
    }
  },
  computed: {
    ...mapGetters(['account']),
    model: function () {
      return this.account
    },
  },
  methods: {
    goToItem(id) {
      this.$router.push({ name: 'showCredentials', params: { id } })
    },
  },
  async mounted() {
    Nprogress.start()

    await this.$store.dispatch('getSpecificAccount', {
      limit: 10,
      skip: 0,
      order: -1,
      id: this.account.id,
    })
    Nprogress.done()
  },
}
</script>
<style></style>
