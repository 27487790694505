<template>
  <div>
    <base-header
      class="header pb-8 pt-8 pt-lg-8 d-flex align-items-center"
      style="overflow: hidden"
    >
      <video
        playsinline
        autoplay
        muted
        loop
        id="myVideo"
        style="position: absolute; left: 0; bottom: 0; width: 100%"
      >
        <source src="/img/video.mp4" type="video/mp4" />
      </video>

      <!-- Mask -->
      <span class="mask bg-gradient-warning opacity-8"></span>
      <!-- Header container -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary" class="nprogress-container">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Global Margin</h3>
                  </div>
                </div>
              </div>
            </template>

            <form
              role="form"
              v-if="this.model"
              name="form"
              @submit.prevent="update"
            >
              <h6 class="heading-small text-muted mb-4">
                Moneybees rates margin
              </h6>
              <div class="pl-lg-4 row">
                <div class="col-lg-6">
                  <base-input
                    alternative=""
                    label="Buy"
                    input-classes="form-control-alternative"
                    v-model="model.buy"
                  />
                </div>
                <div class="col-lg-6">
                  <base-input
                    alternative=""
                    label="Sell"
                    input-classes="form-control-alternative"
                    v-model="model.sell"
                  />
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-warning"
                      :disabled="loading"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Nprogress from 'nprogress'
import { createToast } from 'mosha-vue-toastify'

const progress1 = Nprogress.configure({
  parent: '.nprogress-container',
  trickleSpeed: 50,
  showSpinner: true,
})

export default {
  name: 'margin',
  data() {
    return {
      loading: false,
      moment,
      model: {},
    }
  },
  computed: {
    ...mapGetters(['margin']),
  },
  methods: {
    toast(msg, options) {
      return createToast(msg, options)
    },
    async update() {
      progress1.start()
      this.message = null
      this.loading = true
      return await this.$store
        .dispatch('updateMargin', { ...this.model })
        .then(() => {
          setTimeout(async () => {
            this.loading = false
            this.toast('Successfully updated.', {
              type: 'success',
              position: 'bottom-right',
              timeout: 5000,
            })
            progress1.done()
          }, 420)
        })
        .catch((e) => {
          /**
           *
           * uncaught exceptions
           *
           */
          this.loading = false
          progress1.done()
          console.log('UNCAUGHT PROMISE: ', e)
          const message =
            e.data.message === 'jwt expired'
              ? 'Your session has expired. Login to continue.'
              : e.data.message
          this.toast(message, {
            type: 'danger',
            position: 'bottom-right',
            timeout: 5000,
          })
        })
    },
  },
  async mounted() {
    await this.$store.dispatch('getMargin').then(() => {
      console.log(this.margin)
      this.model = this.margin.global.id
    })
  },
}
</script>
<style></style>
