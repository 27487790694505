<template>
  <div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <div class="card shadow bg-secondary nprogress-container">
            <div class="card-header">
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8"><h3 class="mb-0">Account Details</h3></div>
                  <div class="col-4 text-right">
                    <router-link
                      to="/accounts"
                      class="active btn btn-sm btn-primary"
                    >
                      Show all
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <form role="form" name="form" @submit.prevent="submit" validate>
                <h6 class="heading-small text-muted mb-4">
                  Enter account information
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">Email address</label>
                        <input
                          alternative=""
                          placeholder="hello@example.com"
                          class="form-control form-control-alternative"
                          aria-describedby="addon-right addon-left"
                          v-model="model.email"
                          required
                          type="email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">Mobile</label>
                        <input
                          alternative=""
                          placeholder="63----------"
                          class="form-control form-control-alternative"
                          aria-describedby="addon-right addon-left"
                          v-model="model.mobile"
                          required
                          type="number"
                          @keypress="isNumber($event)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">Company name</label>
                        <input
                          alternative=""
                          placeholder="Feelgood, Inc."
                          class="form-control form-control-alternative"
                          aria-describedby="addon-right addon-left"
                          v-model="model.name"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">Account Type</label>
                        <div class="" label="Company name">
                          <div class="dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              @click="dropdownToggle = !dropdownToggle"
                            >
                              <span v-if="model.accountType === 'merchant'"
                                >Merchant</span
                              >
                              <span v-if="model.accountType === 'submerchant'"
                                >Sub-Merchant</span
                              >
                              <span v-if="model.accountType === 'subuser'"
                                >Sub-User</span
                              >
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                              :style="
                                dropdownToggle
                                  ? 'display: block;'
                                  : 'display: none;'
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="#"
                                @click="
                                  ;(model.accountType = 'merchant'),
                                    (dropdownToggle = !dropdownToggle)
                                "
                                >Merchant</a
                              >
                              <a
                                class="dropdown-item"
                                href="#"
                                @click="
                                  ;(model.accountType = 'submerchant'),
                                    (dropdownToggle = !dropdownToggle)
                                "
                                >Sub-Merchant</a
                              >
                              <a
                                class="dropdown-item"
                                href="#"
                                @click="
                                  ;(model.accountType = 'subuser'),
                                    (dropdownToggle = !dropdownToggle)
                                "
                                >Sub-User</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-6"
                      v-if="model.accountType === 'merchant'"
                    >
                      <div class="form-group has-label">
                        <label class="form-control-label"
                          >Allow Sub-Merchant</label
                        >
                        <div class="" label="Company name">
                          <label
                            class="custom-toggle m-0 mt-2"
                            style="vertical-align: -5px"
                          >
                            <input
                              type="checkbox"
                              id="active"
                              v-model="model.allowSubmerchant"
                              name="active"
                              class="d-block opacity-0"
                            />
                            <span
                              class="custom-toggle-slider rounded-circle"
                            ></span>
                          </label>
                          <label class="m-0 ml-2" for="active"></label>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-6"
                      v-if="
                        model.accountType === 'merchant' ||
                        model.accountType === 'submerchant'
                      "
                    >
                      <div class="form-group has-label">
                        <label class="form-control-label"
                          >Allow Sub-Merchant login</label
                        >
                        <div class="" label="Company name">
                          <label
                            class="custom-toggle m-0 mt-2"
                            style="vertical-align: -5px"
                          >
                            <input
                              type="checkbox"
                              id="active"
                              v-model="model.allowSubmerchantLogin"
                              name="active"
                              class="d-block opacity-0"
                            />
                            <span
                              class="custom-toggle-slider rounded-circle"
                            ></span>
                          </label>
                          <label class="m-0 ml-2" for="active"></label>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-6"
                      v-if="
                        model.accountType === 'merchant' ||
                        model.accountType === 'submerchant'
                      "
                    >
                      <div class="form-group has-label">
                        <label class="form-control-label"
                          >Allow Sub-Users</label
                        >
                        <div class="" label="Company name">
                          <label
                            class="custom-toggle m-0 mt-2"
                            style="vertical-align: -5px"
                          >
                            <input
                              type="checkbox"
                              id="active"
                              v-model="model.allowSubuser"
                              name="active"
                              class="d-block opacity-0"
                            />
                            <span
                              class="custom-toggle-slider rounded-circle"
                            ></span>
                          </label>
                          <label class="m-0 ml-2" for="active"></label>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-6"
                      v-show="this.$route.params.id !== 'new'"
                    >
                      <div class="form-group has-label">
                        <label class="form-control-label">Status</label>
                        <div class="" label="Company name">
                          <label
                            class="custom-toggle m-0 mt-2"
                            style="vertical-align: -5px"
                          >
                            <input
                              type="checkbox"
                              id="active"
                              v-model="model.active"
                              name="active"
                              class="d-block opacity-0"
                            />
                            <span
                              class="custom-toggle-slider rounded-circle"
                            ></span>
                          </label>
                          <label class="m-0 ml-2" for="active">Active</label>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-6"
                      v-show="this.$route.params.id !== 'new'"
                    >
                      <div class="form-group has-label">
                        <label class="form-control-label">Enable SMS OTP</label>
                        <div class="" label="Company name">
                          <label
                            class="custom-toggle m-0 mt-2"
                            style="vertical-align: -5px"
                          >
                            <input
                              type="checkbox"
                              id="active"
                              v-model="model.twoFactorEnabled"
                              name="active"
                              class="d-block opacity-0"
                            />
                            <span
                              class="custom-toggle-slider rounded-circle"
                            ></span>
                          </label>
                          <label class="m-0 ml-2" for="active">Active</label>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group">
                        <button
                          type="submit"
                          class="btn btn-warning"
                          :disabled="loading"
                          v-if="this.$route.params.id !== 'new'"
                        >
                          Update
                        </button>
                        <button
                          type="submit"
                          class="btn btn-success"
                          :disabled="loading"
                          v-if="this.$route.params.id === 'new'"
                        >
                          Create
                        </button>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <base-alert
                        type="danger"
                        :dismissible="true"
                        v-if="error"
                        >{{ error }}</base-alert
                      >
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Nprogress from 'nprogress'
import { createToast } from 'mosha-vue-toastify'

const progress1 = Nprogress.configure({
  parent: '.nprogress-container',
  trickleSpeed: 50,
  showSpinner: true,
})

export default {
  components: {},
  computed: {
    ...mapGetters(['specificAccount']),
  },
  data() {
    return {
      model: { accountType: 'merchant' },
      loading: false,
      error: null,
      dropdownToggle: false,
    }
  },
  methods: {
    submit() {
      if (this.$route.params.id === 'new') this.create()
      if (this.$route.params.id !== 'new') this.update()
    },
    async update() {
      if (!this.loading) {
        this.loading = true
        this.error = null
        progress1.start()
        await this.$store
          .dispatch('updateSpecificAccount', {
            email: this.model.email,
            mobile: this.model.mobile,
            id: this.model.id,
            active: this.model.active,
            accountType: this.model.accountType,
            allowSubmerchant: this.model.allowSubmerchant,
            allowSubuser: this.model.allowSubuser,
            allowSubmerchantLogin: this.model.allowSubmerchantLogin,
            twoFactorEnabled: this.model.twoFactorEnabled,
          })
          .then((res) => {
            setTimeout(() => {
              console.log(res)
              this.loading = false
              progress1.done()
              this.toast('Updated successfully.', {
                type: 'success',
                position: 'bottom-right',
                timeout: 5000,
              })
            }, 420)
          })
          .catch((e) => {
            console.log(e.data)
            if (e.data) {
              setTimeout(() => {
                this.loading = false
                const message =
                  e.data.message === 'jwt expired'
                    ? 'Your session has expired. Login to continue.'
                    : e.data.message
                this.toast(message, {
                  type: 'danger',
                  position: 'bottom-right',
                  timeout: 5000,
                })
                progress1.done()
              }, 420)
            }
          })
        progress1.done()
      }
    },
    async create() {
      if (!this.loading) {
        this.loading = true
        this.error = null
        progress1.start()

        await this.$store
          .dispatch('createAccount', {
            email: this.model.email,
            mobile: this.model.mobile,
            name: this.model.name,
            id: this.model.id,
            accountType: this.model.accountType,
            allowSubmerchant: this.model.allowSubmerchant,
            allowSubuser: this.model.allowSubuser,
            allowSubmerchantLogin: this.model.allowSubmerchantLogin,
          })
          .then((res) => {
            setTimeout(() => {
              console.log(res)
              this.loading = false
              progress1.done()
              this.$router.push('/accounts')
              this.toast('Succesfully added an account', {
                type: 'success',
                position: 'bottom-right',
                timeout: 5000,
              })
            }, 420)
          })
          .catch((e) => {
            console.log(e.data)
            if (e.data) {
              setTimeout(() => {
                this.loading = false
                const message =
                  e.data.message === 'jwt expired'
                    ? 'Your session has expired. Login to continue.'
                    : e.data.message
                this.toast(message, {
                  type: 'danger',
                  position: 'bottom-right',
                  timeout: 5000,
                })
                progress1.done()
              }, 420)
            }
          })
      }
    },
    toast(msg, options) {
      return createToast(msg, options)
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
  async mounted() {
    try {
      progress1.done()
      const id = this.$route.params.id

      if (id !== 'new') {
        await this.$store.dispatch('getSpecificAccount', { id })
        this.model = {
          ...this.specificAccount,
        }
      }

      /**
       *
       *
       */
    } catch (e) {
      /**
       *
       * uncaught exceptions
       *
       */
      console.log('UNCAUGHT PROMISE: ', e)
      const message =
        e.data.message === 'jwt expired'
          ? 'Your session has expired. Login to continue.'
          : e.data.message
      this.toast(message, {
        type: 'danger',
        position: 'bottom-right',
        timeout: 5000,
      })
    }
  },
}
</script>
<style></style>
