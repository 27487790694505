<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5 text-center nprogress-container">
          <router-link class="navbar-brand" to="/">
            <img
              :src="logo"
              class="navbar-brand-img"
              alt="..."
              style="max-height: 4.5rem"
            />
          </router-link>

          <div class="login-text text-muted text-center mt-2 mb-3">
            <small>Continue signing up</small>
          </div>

          <form role="form" name="form" @submit.prevent="signup" validate>
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Confirm Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="password"
              autocomplete="off"
              list="autocompleteOff"
              :disabled="loading"
              :required="true"
            >
            </base-input>
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="confirmPassword"
              autocomplete="off"
              list="autocompleteOff"
              :disabled="loading"
              :required="true"
            >
            </base-input>

            <div class="text-center">
              <button
                type="submit"
                class="btn btn-primary my-4"
                :disabled="loading || !password || confirmPassword !== password"
              >
                Continue
              </button>
            </div>

            <base-alert type="danger" :dismissible="true" v-if="error">{{
              error
            }}</base-alert>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nprogress from 'nprogress'
import BaseAlert from '../components/BaseAlert.vue'
import { createToast } from 'mosha-vue-toastify'

Nprogress.configure({
  parent: '.nprogress-container',
  trickleSpeed: 50,
  showSpinner: true,
})

export default {
  components: { BaseAlert },
  name: 'login',
  props: {
    logo: {
      type: String,
      default: '/img/brand/green.png',
      description: 'Sidebar app logo',
    },
  },

  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      error: null,
      loading: false,
      passwordType: 'password',
    }
  },

  methods: {
    signup() {
      if (!this.loading) {
        this.loading = true
        this.error = null
        Nprogress.start()

        this.$store
          .dispatch('signup', {
            password: this.password,
            token: this.$route.query.token,
          })
          .then((res) => {
            console.log({ res })
            Nprogress.done()
            setTimeout(() => {
              this.loading = false
              this.toast('You may now login to your account.', {
                type: 'success',
                position: 'bottom-right',
                timeout: 5000,
              })
              this.$router.push('/login')
            }, 420)
          })
          .catch((err) => {
            console.log('ERROR AT LOGIN: ', err)
            if (err.data) {
              setTimeout(() => {
                this.loading = false
                // this.error = err.data.message
                this.toast(err.data.message, {
                  type: 'danger',
                  position: 'bottom-right',
                  timeout: 5000,
                })
                Nprogress.done()
              }, 420)
            }
          })
      }
    },
    toast(msg, options) {
      return createToast(msg, options)
    },
  },
  mounted() {
    Nprogress.done()
  },
}
</script>
<style></style>
