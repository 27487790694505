import axios from 'axios'
const apiURL = process.env.VUE_APP_API_URL

export default {
  state: {
    accessToken: null,
    account: null,
    accounts: [],
    specificAccount: null,
  },
  mutations: {
    SET_ACCOUNT_DATA(state, data) {
      state.accessToken = data.accessToken
      state.account = data.account

      localStorage.setItem('mb_portal_accessToken', data.accessToken)
      localStorage.setItem('mb_portal_account', JSON.stringify(data.account))
    },
    SET_ACCOUNT(state, data) {
      state.account = {
        ...state.account,
        ...data,
      }

      console.log('Account updated: ', state.account)
      localStorage.setItem('mb_portal_account', JSON.stringify(state.account))
    },
    SET_ACCOUNTS(state, data) {
      state.accounts = data
    },
    SET_SPECIFIC_ACCOUNT(state, data) {
      state.specificAccount = data
    },
  },
  actions: {
    login({ commit }, credentials) {
      return axios
        .post(`${apiURL}/authentication`, credentials, {
          headers: {
            Authorization: null,
          },
        })
        .then(({ data }) => {
          if (data.account) {
            return commit('SET_ACCOUNT_DATA', data)
          }

          return data
        })
    },
    signup({ commit }, credentials) {
      console.log(commit)
      return axios
        .post(`${apiURL}/signup`, credentials, {
          headers: {
            Authorization: null,
          },
        })
        .then(({ data }) => data)
    },
    logout({ commit }) {
      commit('SET_ACCOUNT_DATA', { account: null, accessToken: null })
      delete localStorage.mb_portal_accessToken
      delete localStorage.mb_portal_account
    },
    async getMyAccount({ commit }, id) {
      return await axios
        .get(`${apiURL}/accounts/${id}`)
        .then((account) => commit('SET_ACCOUNT', account.data))
    },
    async updateMyAccount(args, option) {
      return await axios.put(`${apiURL}/accounts/${option.id}`, {
        mobile: option.mobile,
        password: option.password,
        oldPassword: option.oldPassword,
      })
    },
    async getAccounts({ commit }, options) {
      return await axios
        .get(
          `${apiURL}/accounts?$limit=${
            options.limit ? options.limit : 10
          }&$sort[id]=${options.sort ? options.sort : -1}&$skip=${
            options.skip ? options.skip : 0
          }${options.accountType ? '&accountType=' + options.accountType : ''}`
        )
        .then((accounts) => commit('SET_ACCOUNTS', accounts.data))
    },
    async getSpecificAccount({ commit }, options) {
      return await axios
        .get(`${apiURL}/accounts/${options.id}`)
        .then((account) => commit('SET_SPECIFIC_ACCOUNT', account.data))
    },
    async updateSpecificAccount({ commit }, options) {
      return await axios
        .put(`${apiURL}/accounts/${options.id}`, {
          email: options.email,
          mobile: options.mobile,
          active: options.active,
          accountType: options.accountType,
          allowSubmerchant: options.allowSubmerchant,
          allowSubuser: options.allowSubuser,
          allowSubmerchantLogin: options.allowSubmerchantLogin,
          twoFactorEnabled: options.twoFactorEnabled,
        })
        .then((account) => commit('SET_SPECIFIC_ACCOUNT', account.data))
    },
    async createAccount({ commit }, options) {
      return await axios
        .post(`${apiURL}/accounts`, options)
        .then((account) => commit('SET_SPECIFIC_ACCOUNT', account.data))
    },
  },
  getters: {
    accessToken(state) {
      return state.accessToken
    },
    account(state) {
      try {
        return state.account
      } catch (e) {
        console.log('Error getting account state', e)
      }
    },
    accounts(state) {
      try {
        return state.accounts
      } catch (e) {
        console.log('Error getting accounts', e)
      }
    },
    specificAccount(state) {
      return state.specificAccount
    },
  },
}
