import axios from 'axios'
const apiURL = process.env.VUE_APP_API_URL

export default {
  state: {
    deposit: null,
    deposits: [],
  },
  mutations: {
    SET_DEPOSIT(state, data) {
      state.deposit = data
    },
    SET_DEPOSITS(state, data) {
      state.deposits = data
    },
  },
  actions: {
    async getDeposits({ commit }, options) {
      return await axios
        .get(
          `${apiURL}/deposits?$limit=${
            options.limit ? options.limit : 10
          }&$sort[id]=${options.sort ? options.sort : -1}&$skip=${
            options.skip ? options.skip : 0
          }`
        )
        .then((deposits) => commit('SET_DEPOSITS', deposits.data))
    },
    async getDeposit({ commit }, options) {
      return await axios
        .get(`${apiURL}/deposits/${options.id}`)
        .then((deposit) => commit('SET_DEPOSIT', deposit.data))
    },
    async updateDeposit({ commit }, options) {
      return await axios
        .put(`${apiURL}/deposits/${options.id}`, {
          state: options.state,
        })
        .then((deposit) => commit('SET_DEPOSIT', deposit.data))
    },
    async createDeposit({ commit }, options) {
      return await axios
        .post(`${apiURL}/deposits`, options)
        .then((deposit) => commit('SET_DEPOSIT', deposit.data))
    },
  },
  getters: {
    deposit(state) {
      try {
        return state.deposit
      } catch (e) {
        console.log('Error getting deposit state', e)
      }
    },
    deposits(state) {
      try {
        return state.deposits
      } catch (e) {
        console.log('Error getting deposits', e)
      }
    },
  },
}
