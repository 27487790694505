<script>
import BranchesTable from '../Tables/BranchesTable'
import { mapGetters } from 'vuex'
import Nprogress from 'nprogress'
import { createToast } from 'mosha-vue-toastify'

Nprogress.configure({
  parent: '.nprogress-container',
  trickleSpeed: 50,
  showSpinner: true,
})

export default {
  components: {
    BranchesTable,
  },
  data() {
    return {
      branchData: [],
      error: null,
    }
  },
  computed: {
    ...mapGetters(['branch', 'branches']),
  },
  watch: {
    branches(val) {
      if (val) {
        this.branchData = val.data
        Nprogress.done()
      }
      return val
    },
  },
  methods: {
    async changePage(page) {
      try {
        Nprogress.start()
        const newPage = page - 1
        const skip = 10 * newPage
        await this.$store.dispatch('getBranches', {
          limit: 10,
          skip,
          order: -1,
        })
        Nprogress.done()
        /**
         *
         *
         */
      } catch (e) {
        /**
         *
         * uncaught exceptions
         *
         */
        console.log('UNCAUGHT PROMISE: ', e)
        const message =
          e.data.message === 'jwt expired'
            ? 'Your session has expired. Login to continue.'
            : e.data.message
        this.toast(message, {
          type: 'danger',
          position: 'bottom-right',
          timeout: 5000,
        })
      }
    },
    toast(msg, options) {
      return createToast(msg, options)
    },
  },
  async mounted() {
    try {
      this.branchData = []
      this.error = null
      Nprogress.start()

      await this.$store.dispatch('getBranches', {
        limit: 10,
        skip: 0,
        order: -1,
      })
      Nprogress.done()
      /**
       *
       *
       */
    } catch (e) {
      /**
       *
       * uncaught exceptions
       *
       */
      Nprogress.done()
      console.log('UNCAUGHT PROMISE: ', e)
      const message =
        e.data.message === 'jwt expired'
          ? 'Your session has expired. Login to continue.'
          : e.data.message
      this.toast(message, {
        type: 'danger',
        position: 'bottom-right',
        timeout: 5000,
      })
    }
  },
}
</script>

<template>
  <div class="container-fluid mt--7">
    <branches-table v-if="branchData" v-bind:tableData="branchData" :title="'Branches'" :paginate="{ branches }"
      @changePage="changePage" :listRoute="'showBranch'" :buttonRoute="'/branches/new'"
      :buttonText="'Create New Branch'" class="nprogress-container"></branches-table>
    <base-alert type="danger" :dismissible="true" v-if="error">{{
    error
    }}</base-alert>
  </div>
</template>

<style>

</style>
