import axios from 'axios'
const apiURL = process.env.VUE_APP_API_URL

export default {
  state: {
    withdrawal: null,
    withdrawals: [],
  },
  mutations: {
    SET_WITHDRAWAL(state, data) {
      state.withdrawal = data
    },
    SET_WITHDRAWALS(state, data) {
      state.withdrawals = data
    },
  },
  actions: {
    async getWithdrawals({ commit }, options) {
      return await axios
        .get(
          `${apiURL}/withdrawals?$limit=${
            options.limit ? options.limit : 10
          }&$sort[id]=${options.sort ? options.sort : -1}&$skip=${
            options.skip ? options.skip : 0
          }`
        )
        .then((withdrawals) => commit('SET_WITHDRAWALS', withdrawals.data))
    },
    async getWithdrawal({ commit }, options) {
      return await axios
        .get(`${apiURL}/withdrawals/${options.id}`)
        .then((withdrawal) => commit('SET_WITHDRAWAL', withdrawal.data))
    },
    async updateWithdrawal({ commit }, options) {
      return await axios
        .put(`${apiURL}/withdrawals/${options.id}`, {
          state: options.state,
          hash: options.hash,
        })
        .then((withdrawal) => commit('SET_WITHDRAWAL', withdrawal.data))
    },
    async createWithdrawal({ commit }, options) {
      return await axios
        .post(`${apiURL}/withdrawals`, options)
        .then((withdrawal) => commit('SET_WITHDRAWAL', withdrawal.data))
    },
  },
  getters: {
    withdrawal(state) {
      try {
        return state.withdrawal
      } catch (e) {
        console.log('Error getting withdrawal state', e)
      }
    },
    withdrawals(state) {
      try {
        return state.withdrawals
      } catch (e) {
        console.log('Error getting withdrawals', e)
      }
    },
  },
}
