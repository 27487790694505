<template>
  <div class="container-fluid mt--7">
    <accounts-table
      v-if="accountData"
      v-bind:tableData="accountData"
      :title="'Sub-Users'"
      :paginate="{ accounts }"
      @changePage="changePage"
      :listRoute="'showSubuser'"
      :buttonRoute="'/subusers/new'"
      :buttonText="'Create New Sub-User'"
      class="nprogress-container"
    ></accounts-table>
    <base-alert type="danger" :dismissible="true" v-if="error">{{
      error
    }}</base-alert>
  </div>
</template>
<script>
import AccountsTable from '../Tables/AccountsTable'
import { mapGetters } from 'vuex'
import Nprogress from 'nprogress'
import { createToast } from 'mosha-vue-toastify'

Nprogress.configure({
  parent: '.nprogress-container',
  trickleSpeed: 50,
  showSpinner: true,
})

export default {
  components: {
    AccountsTable,
    // SocialTrafficTable,
  },
  data() {
    return {
      accountData: [],
      error: null,
    }
  },
  computed: {
    ...mapGetters(['account', 'accounts']),
  },
  watch: {
    accounts(val) {
      if (val) {
        this.accountData = val.data
        Nprogress.done()
      }
      return val
    },
  },
  methods: {
    async changePage(page) {
      try {
        Nprogress.start()
        const newPage = page - 1
        const skip = 10 * newPage
        await this.$store.dispatch('getAccounts', {
          limit: 10,
          skip,
          order: -1,
          accountType: 'subuser',
        })
        Nprogress.done()
        /**
         *
         *
         */
      } catch (e) {
        /**
         *
         * uncaught exceptions
         *
         */
        console.log('UNCAUGHT PROMISE: ', e)
        const message =
          e.data.message === 'jwt expired'
            ? 'Your session has expired. Login to continue.'
            : e.data.message
        this.toast(message, {
          type: 'danger',
          position: 'bottom-right',
          timeout: 5000,
        })
      }
    },
    toast(msg, options) {
      return createToast(msg, options)
    },
  },
  async mounted() {
    try {
      this.accountData = []
      this.error = null
      Nprogress.start()

      await this.$store.dispatch('getAccounts', {
        limit: 10,
        skip: 0,
        order: -1,
        accountType: 'subuser',
      })
      Nprogress.done()
      /**
       *
       *
       */
    } catch (e) {
      /**
       *
       * uncaught exceptions
       *
       */
      Nprogress.done()
      console.log('UNCAUGHT PROMISE: ', e)
      const message =
        e.data.message === 'jwt expired'
          ? 'Your session has expired. Login to continue.'
          : e.data.message
      this.toast(message, {
        type: 'danger',
        position: 'bottom-right',
        timeout: 5000,
      })
    }
  },
}
</script>
<style></style>
