<template>
  <div class="row">
    <div class="col-xl-12 order-xl-1">
      <card shadow type="secondary" class="nprogress-container">
        <template v-slot:header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">Credential details</h3>
              </div>
              <div class="col-4 text-right">
                <router-link
                  to="/credentials"
                  class="active btn btn-sm btn-primary"
                >
                  Back
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <!-- 
          *
          *
          * Update
          *
          *
         -->

        <div class="col-lg-12">
          <base-alert type="warning" :dismissible="true" v-if="message">
            This message will only be shown once, save this API secret somewhere
            safe:
            {{ message }}
          </base-alert>
        </div>
        <div v-if="this.$route.params.id !== 'new'">
          <h6 class="heading-small text-muted mb-4"></h6>
          <div class="pl-lg-4 row">
            <div class="col-lg-6">
              <div class="form-group has-label">
                <label class="form-control-label">Label</label>
                <input
                  v-if="this.model[0]"
                  alternative=""
                  class="form-control form-control-alternative"
                  aria-describedby="addon-right addon-left"
                  v-model="this.model[0].name"
                  required
                  placeholder="Eg. Production, localhost, etc."
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group has-label">
                <label class="form-control-label">IP Address</label>
                <input
                  v-if="this.model[0]"
                  alternative=""
                  class="form-control form-control-alternative"
                  aria-describedby="addon-right addon-left"
                  v-model="this.model[0].ipAddress"
                  required
                  disabled
                  placeholder="127.0.0.1"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group has-label">
                <label class="form-control-label">API Key</label>
                <input
                  v-if="this.model[0]"
                  alternative=""
                  class="form-control form-control-alternative"
                  aria-describedby="addon-right addon-left"
                  v-model="this.model[0].apiKey"
                  required
                  disabled
                  placeholder="127.0.0.1"
                />
              </div>
            </div>
            <div class="col-lg-6" v-if="this.model[0]">
              <div class="form-group has-label">
                <label class="form-control-label">Created</label>
                <div class="text-sm">
                  {{ moment(model[0].createdAt).format('LLL') }}
                  <span>({{ moment(model[0].createdAt).fromNow() }})</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12" v-if="this.model[0]">
              <div class="form-group">
                <button
                  type="submit"
                  class="btn"
                  :class="model[0].active ? 'btn-warning' : 'btn-info'"
                  :disabled="loading"
                  @click="this.update(model[0].id)"
                >
                  {{ model[0].active ? 'Deactivate' : 'Activate' }}
                </button>
                <span
                  @click="confirmDelete"
                  class="text-danger text-sm ml-2 hover-pointer"
                  >Or delete this key</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- 
          *
          *
          * Create new
          *
          *
         -->
        <div v-if="this.$route.params.id === 'new'">
          <form
            class="pl-lg-4 row"
            role="form"
            name="form"
            @submit.prevent="create"
          >
            <div class="col-lg-6">
              <div class="form-group has-label">
                <label class="form-control-label">Label</label>
                <input
                  alternative=""
                  class="form-control form-control-alternative"
                  aria-describedby="addon-right addon-left"
                  v-model="model.name"
                  required
                  placeholder="Eg. Production, localhost, etc."
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group has-label">
                <label class="form-control-label">IP Address</label>
                <input
                  alternative=""
                  class="form-control form-control-alternative"
                  aria-describedby="addon-right addon-left"
                  v-model="model.ipAddress"
                  required
                  placeholder="127.0.0.1"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="loading"
                  v-if="this.$route.params.id === 'new'"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>

    <div class="col-md-4">
      <div
        class="modal fade"
        id="modal-notification"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modal-notification"
        aria-hidden="true"
        :class="this.deleteModal ? 'show d-block' : ''"
      >
        <div
          class="modal-dialog modal-danger modal-dialog-centered modal-"
          role="document"
        >
          <div class="modal-content bg-gradient-danger">
            <div class="modal-header">
              <h6 class="modal-title" id="modal-title-notification">
                Confirm your action
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="py-3 text-center">
                <i class="ni ni-bell-55 ni-3x"></i>
                <h4 class="heading mt-4">Are you sure?</h4>
                <p>You will lose this API key forever.</p>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-white"
                @click="this.delete(this.model[0].id)"
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-link text-white ml-auto"
                data-dismiss="modal"
                @click="deleteModal = false"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from 'axios'
import Nprogress from 'nprogress'
import { createToast } from 'mosha-vue-toastify'

const progress2 = Nprogress.configure({
  parent: '.nprogress-container',
  trickleSpeed: 50,
  showSpinner: true,
})

const apiURL = process.env.VUE_APP_API_URL

export default {
  data() {
    return {
      loading: false,
      moment,
      message: null,
      deleteModal: false,
    }
  },
  computed: {
    ...mapGetters(['account']),
    accountInfo: function () {
      return this.account
    },
    model: function () {
      const id = +this.$route.params.id
      return this.account.credentials.filter((a) => {
        if (a.id === id) return a
      })
    },
  },

  methods: {
    toast(msg, options) {
      return createToast(msg, options)
    },
    async update(id) {
      progress2.start()
      this.message = null
      return await axios
        .put(`${apiURL}/credentials/${id}`, { type: 'toggle' })
        .then(() => {
          setTimeout(async () => {
            this.loading = false
            this.toast('Successfully updated.', {
              type: 'success',
              position: 'bottom-right',
              timeout: 5000,
            })
            progress2.done()
            await this.$store.dispatch('getMyAccount', this.account.id)
            this.$router.push('/credentials')
          }, 420)
        })
        .catch((e) => {
          /**
           *
           * uncaught exceptions
           *
           */
          this.loading = false
          progress2.done()
          console.log('UNCAUGHT PROMISE: ', e)
          const message =
            e.data.message === 'jwt expired'
              ? 'Your session has expired. Login to continue.'
              : e.data.message
          this.toast(message, {
            type: 'danger',
            position: 'bottom-right',
            timeout: 5000,
          })
        })
    },
    confirmDelete() {
      this.deleteModal = true
    },
    async delete(id) {
      progress2.start()
      return await axios
        .put(`${apiURL}/credentials/${id}`, { type: 'delete' })
        .then(() => {
          setTimeout(async () => {
            this.loading = false
            this.toast('Successfully deleted.', {
              type: 'success',
              position: 'bottom-right',
              timeout: 5000,
            })
            progress2.done()
            await this.$store.dispatch('getMyAccount', this.account.id)
            this.$router.push('/credentials')
          }, 420)
        })
        .catch((e) => {
          /**
           *
           * uncaught exceptions
           *
           */
          this.loading = false
          progress2.done()
          console.log('UNCAUGHT PROMISE: ', e)
          const message =
            e.data.message === 'jwt expired'
              ? 'Your session has expired. Login to continue.'
              : e.data.message
          this.toast(message, {
            type: 'danger',
            position: 'bottom-right',
            timeout: 5000,
          })
        })
    },
    async create() {
      progress2.start()
      this.message = null
      return await axios
        .post(`${apiURL}/credentials`, {
          name: this.model.name,
          ipAddress: this.model.ipAddress,
        })
        .then((res) => {
          setTimeout(async () => {
            this.loading = false
            this.toast('Successfully created.', {
              type: 'success',
              position: 'bottom-right',
              timeout: 5000,
            })
            progress2.done()
            await this.$store.dispatch('getMyAccount', this.account.id)
            this.$router.push(`/credentials/credentials/${res.data.id}`)
            this.message = res.data.apiSecret
          }, 420)
        })
        .catch((e) => {
          /**
           *
           * uncaught exceptions
           *
           */
          this.loading = false
          progress2.done()
          console.log('UNCAUGHT PROMISE: ', e)
          const message =
            e.data.message === 'jwt expired'
              ? 'Your session has expired. Login to continue.'
              : e.data.message
          this.toast(message, {
            type: 'danger',
            position: 'bottom-right',
            timeout: 5000,
          })
        })
    },
  },

  async mounted() {},
}
</script>
<style></style>
