<template>
  <div>
    <base-header
      class="header pb-8 pt-8 pt-lg-8 d-flex align-items-center"
      style="overflow: hidden"
    >
      <video
        playsinline
        autoplay
        muted
        loop
        id="myVideo"
        style="position: absolute; left: 0; bottom: 0; width: 100%"
      >
        <source src="/img/video.mp4" type="video/mp4" />
      </video>

      <!-- Mask -->
      <span class="mask bg-gradient-warning opacity-8"></span>
      <!-- Header container -->
    </base-header>

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Nprogress from 'nprogress'

Nprogress.configure({
  parent: '.nprogress-container',
  trickleSpeed: 50,
  showSpinner: true,
})

export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['account', 'accounts']),
  },

  methods: {},
  async mounted() {
    // try {
    //   await this.$store.dispatch('getAccounts', { limit: 10, order: -1 })
    // } catch (e) {
    //   console.log('Error mounting accounts page: ', e)
    // }
  },
}
</script>
<style></style>
