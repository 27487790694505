import accountStore from './stores/account'
import depositStore from './stores/deposit'
import walletStore from './stores/wallet'
import addressStore from './stores/address'
import withdrawalStore from './stores/withdrawal'
import marginStore from './stores/margin'
import branchStore from './stores/branch'
import merchantStore from './stores/merchant'
import { createStore } from 'vuex'

// Create a new store instance.
const storeConfig = {
  state: {
    ...accountStore.state,
    ...depositStore.state,
    ...walletStore.state,
    ...addressStore.state,
    ...withdrawalStore.state,
    ...marginStore.state,
    ...branchStore.state,
    ...merchantStore.state,
  },
  mutations: {
    ...accountStore.mutations,
    ...depositStore.mutations,
    ...walletStore.mutations,
    ...addressStore.mutations,
    ...withdrawalStore.mutations,
    ...marginStore.mutations,
    ...branchStore.mutations,
    ...merchantStore.mutations,
  },
  actions: {
    ...accountStore.actions,
    ...depositStore.actions,
    ...walletStore.actions,
    ...addressStore.actions,
    ...withdrawalStore.actions,
    ...marginStore.actions,
    ...branchStore.actions,
    ...merchantStore.actions,
  },
  getters: {
    ...accountStore.getters,
    ...depositStore.getters,
    ...walletStore.getters,
    ...addressStore.getters,
    ...withdrawalStore.getters,
    ...marginStore.getters,
    ...branchStore.getters,
    ...merchantStore.getters,
  },
}

const store = createStore(storeConfig)
export default store
