import axios from 'axios'
const apiURL = process.env.VUE_APP_API_URL

export default {
  state: {
    branch: null,
    branches: [],
  },
  mutations: {
    SET_BRANCH(state, data) {
      state.branch = data
    },
    SET_BRANCHES(state, data) {
      state.branches = data
    },
  },
  actions: {
    async getBranches({ commit }, options) {
      return await axios
        .get(
          `${apiURL}/branches?$limit=${
            options.limit ? options.limit : 10
          }&$sort[id]=${options.sort ? options.sort : -1}&$skip=${
            options.skip ? options.skip : 0
          }`
        )
        .then((branches) => commit('SET_BRANCHES', branches.data))
    },
    async getBranch(args, options) {
      return await axios
        .get(`${apiURL}/branches/${options.id}`)
        .then((branch) => {
          return branch.data
        })
    },
    async updateBranch({ commit }, options) {
      return await axios
        .put(`${apiURL}/branches/${options.id}`, {
          status: options.status,
          name: options.name,
          number: options.number,
          address: options.address,
          outletCode: options.outletCode,
          mapUrl: options.mapUrl,
          opHours: options.opHours,
        })
        .then((branch) => commit('SET_BRANCH', branch.data))
    },
    async createBranch({ commit }, options) {
      return await axios
        .post(`${apiURL}/branches`, options)
        .then((branch) => commit('SET_BRANCH', branch.data))
    },
  },
  getters: {
    branch(state) {
      try {
        return state.branch
      } catch (e) {
        console.log('Error getting branch state', e)
      }
    },
    branches(state) {
      try {
        return state.branches
      } catch (e) {
        console.log('Error getting branches', e)
      }
    },
  },
}
