<template>
  <div class="header" :class="{ [`bg-${type}`]: type }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'base-header',
  props: {
    type: {
      type: String,
      default: 'primary',
      description: 'Header background type',
    },
  },
}
</script>
<style></style>
