<template>
  <div class="main-content bg-default" style="min-height: 100vh">
    <!-- Navbar -->
    <!-- <base-nav
      class="navbar-top navbar-horizontal navbar-dark"
      containerClasses="px-4 container"
      expand
    >
      <template v-slot="{ closeMenu }">
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img src="img/brand/green.png" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                @click="closeMenu"
                class="navbar-toggler"
                aria-label="Toggle sidenav"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/login">
              <i class="ni ni-key-25"></i>
              <span class="nav-link-inner--text">Login</span>
            </router-link>
          </li>
        </ul>
      </template>
    </base-nav> -->
    <!-- Header -->
    <div class="header bg-gradient-success py-8">
      <div class="separator separator-bottom separator-skew zindex-100" style="z-index: 1">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>

      <base-header class="header pb-8 pt-8 pt-lg-8 d-flex align-items-center"
        style="position: absolute; top: 0; height: 450px; width: 100%">
        <video autoplay muted loop id="myVideo" style="position: absolute; left: 0; bottom: 0; width: 100%">
          <source src="/img/video.mp4" type="video/mp4" />
        </video>

        <!-- Mask -->
        <span class="mask bg-gradient-warning opacity-8"></span>
        <!-- Header container -->
      </base-header>

      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <h1 class="text-white">Welcome</h1>
              <p class="text-lead text-white">Login to Merchant Portal</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5" style="position: relative; z-index: 2">
      <router-view></router-view>
    </div>
    <footer class="py-5">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              &copy; {{ year }}
              <a href="#" class="font-weight-bold ml-1" target="_blank">Moneybees Backoffice</a>
            </div>
          </div>
          <div class="col-xl-6">
            <ul class="nav nav-footer justify-content-center justify-content-xl-end">
              <li class="nav-item">
                <a href="#" class="nav-link" target="_blank">Terms & Conditions</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'auth-layout',
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    }
  },
}
</script>
<style>

</style>
