/*!
=========================================================
* Wallet API Dashboard
=========================================================
*/
import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import axios from 'axios'

import ArgonDashboard from './plugins/argon-dashboard'
import 'element-plus/lib/theme-chalk/index.css'
import 'mosha-vue-toastify/dist/style.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueCountdown from '@chenfengyuan/vue-countdown'

const recaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY

// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'

const app = createApp({
  created() {
    try {
      let account = localStorage.getItem('mb_portal_account')
      const accessToken = localStorage.getItem('mb_portal_accessToken')

      if (account && accessToken) {
        account = JSON.parse(account)
        this.$store.commit('SET_ACCOUNT_DATA', {
          account,
          accessToken,
        })
      }

      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          console.log('ERROR AT ROUTER INTERCEPTOR: ', error)
          if (error) {
            if (error.status && error.status === 401) {
              store.dispatch('logout')
              router.push('login')
              return Promise.reject(error)
            } else if (error.response && error.response.status === 401) {
              store.dispatch('logout')
              router.push('login')
              return Promise.reject(error.response)
            } else {
              return Promise.reject(error.response ? error.response : error)
            }
          }
        }
      )
    } catch (e) {
      console.log('ERROR AT MAIN: ', e)
    }
  },
  render() {
    return h(App)
  },
})
// Install the store instance as a plugin
app.use(store)

// Sentry.init({
//   app,
//   dsn:
//     'https://e0dd7481e45b4f55a6cefe59c571b948@o1115519.ingest.sentry.io/6149423',
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: [
//         'localhost',
//         'https://staging-wallet-api.paylance.com',
//         /^\//,
//       ],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

app.use(VueReCaptcha, { siteKey: recaptchaKey })
app.component(VueCountdown.name, VueCountdown)
app.use(router)
app.use(ArgonDashboard)
app.mount('#app')
