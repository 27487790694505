<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">{{ this.title }}</h3>
        </div>
        <div class="col text-right">
          <router-link v-if="this.buttonRoute" class="btn btn-sm btn-primary" :to="this.buttonRoute">{{ this.buttonText
          }}</router-link>
        </div>
      </div>
    </div>

    <div class="table-responsive table-hover">
      <div class="loader" v-if="!propagateData">Loading ...</div>
      <div class="loader" v-if="propagateData && !propagateData.length">
        No data
      </div>
      <table class="table tablesorter" v-if="propagateData.length">
        <thead>
          <th>id</th>
          <th>Name</th>
          <th>Outlet Code</th>
          <th>Status</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in propagateData" :key="index" class="hover-pointer" @click="goToItem(item.id)">
            <th scope="row">
              {{ item.id }}
            </th>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.outletCode }}
            </td>
            <td>
              <span class="text-success" v-if="item.status === 'active'">Active</span>
              <span class="text-warning" v-if="item.status === 'pending'">Pending</span>
              <span class="text-danger" v-if="item.status === 'closed'">Closed</span>
              <span class="text-secondary" v-if="item.status === 'paused'">Paused</span>
              <span class="text-danger" v-if="item.status === 'suspended'">Suspended</span>
            </td>
            <td>
              <span :class="item.active ? 'text-success' : 'text-danger'" class="text-uppercase">{{ item.active
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-4 mb-4 d-flex justify-content-center" v-if="propagateData.length">
      <base-pagination v-if="paginate" :perPage="propagateData.limit" :pageCount="pageCounts()" @input="changePage"
        :value="currentPage()"></base-pagination>
    </div>

    <div class="row align-items-center">
      <div class="col"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'branches-table',
  props: [
    'tableData',
    'title',
    'buttonText',
    'buttonRoute',
    'paginate',
    'listRoute',
  ],
  computed: {
    propagateData: function () {
      return this.tableData
    },
  },
  emits: ['changePage'],
  methods: {
    goToItem(id) {
      this.$router.push({ name: this.listRoute, params: { id } })
    },
    pageCounts() {
      try {
        const paginate = this.paginate.branches
        const mod = paginate.total % paginate.limit
        let count = Math.floor(paginate.total / paginate.limit)
        if (mod) count++
        return count
      } catch (e) {
        console.log(e)
      }
    },
    changePage(item) {
      this.$emit('changePage', item)
    },
    currentPage() {
      let page = this.paginate.branches.skip / this.paginate.branches.limit
      page = page + 1
      return page
    },
  },
  mounted() { },
}
</script>
<style>

</style>
