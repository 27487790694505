import axios from 'axios'
const apiURL = process.env.VUE_APP_API_URL

export default {
  state: {
    address: null,
    addresses: [],
  },
  mutations: {
    SET_ADDRESS(state, data) {
      state.address = data
    },
    SET_ADDRESSES(state, data) {
      state.addresses = data
    },
  },
  actions: {
    async getAddresses({ commit }, options) {
      return await axios
        .get(
          `${apiURL}/addresses?$limit=${
            options.limit ? options.limit : 10
          }&$sort[id]=${options.sort ? options.sort : -1}&$skip=${
            options.skip ? options.skip : 0
          }`
        )
        .then((addresses) => commit('SET_ADDRESSES', addresses.data))
    },
    async getAddress({ commit }, options) {
      return await axios
        .get(`${apiURL}/addresses/${options.id}`)
        .then((address) => commit('SET_ADDRESS', address.data))
    },
    async updateAddress({ commit }, options) {
      return await axios
        .put(`${apiURL}/addresses/${options.id}`, {
          type: options.type,
        })
        .then((address) => commit('SET_ADDRESS', address.data))
    },
    async createAddress({ commit }, options) {
      return await axios
        .post(`${apiURL}/addresses`, options)
        .then((address) => commit('SET_ADDRESS', address.data))
    },
  },
  getters: {
    address(state) {
      try {
        return state.address
      } catch (e) {
        console.log('Error getting address state', e)
      }
    },
    addresses(state) {
      try {
        return state.addresses
      } catch (e) {
        console.log('Error getting addresses', e)
      }
    },
  },
}
