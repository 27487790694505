import axios from 'axios'
const apiURL = process.env.VUE_APP_API_URL

export default {
  state: {
    margin: {},
  },
  mutations: {
    SET_MARGIN(state, data) {
      state.margin = data
    },
  },
  actions: {
    async getMargin({ commit }) {
      return await axios
        .get(`${apiURL}/margin`)
        .then((margin) => commit('SET_MARGIN', margin.data))
    },
    async updateMargin({ commit }, options) {
      return await axios
        .put(`${apiURL}/margin`, {
          buy: options.buy,
          sell: options.sell,
        })
        .then((margin) => commit('SET_MARGIN', margin.data))
    },
  },
  getters: {
    margin(state) {
      try {
        return state.margin
      } catch (e) {
        console.log('Error getting wallet state', e)
      }
    },
  },
}
