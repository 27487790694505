<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5 text-center nprogress-container">
          <router-link class="navbar-brand" to="/">
            <img
              :src="logo"
              class="navbar-brand-img"
              alt="..."
              style="max-height: 4.5rem"
            />
          </router-link>

          <div class="login-text text-muted text-center mt-2 mb-3">
            <small>Sign in with your email and password</small>
          </div>

          <form role="form" name="form" @submit.prevent="login" validate>
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="email"
              autocomplete="off"
              list="autocompleteOff"
              :disabled="loading"
              :required="true"
              type="email"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="password"
              autocomplete="off"
              list="autocompleteOff"
              :disabled="loading"
              :required="true"
            >
            </base-input>

            <div class="text-center">
              <button
                type="submit"
                class="btn btn-primary my-4"
                :disabled="loading"
              >
                Sign in
              </button>
            </div>

            <base-alert type="danger" :dismissible="true" v-if="error">{{
              error
            }}</base-alert>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="authyModal"
      tabindex="-1"
      aria-labelledby="authyModal"
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Two-factor authentication
            </h5>
          </div>
          <div class="modal-body">
            <span class="h5" v-show="modal.countdown === 'on'">
              {{ modal.message }} Type in the code sent to your mobile within
              <vue-countdown
                :time="modal.seconds_to_expire * 1000"
                v-slot="{ minutes, seconds }"
                @end="modal.countdown = 'off'"
                class="text-warning"
              >
                {{ minutes }} minutes, {{ seconds }} seconds.
              </vue-countdown>
            </span>
            <span
              class="text-center d-block"
              v-if="modal.countdown === 'off' && !loading"
            >
              Your code has expired
            </span>

            <form
              name="authyForm"
              class="mb-3 mt-3"
              v-show="modal.countdown === 'on'"
              @submit="validateCode($event)"
            >
              <div class="pinGroup">
                <div class="pinKeys">
                  <input
                    min="0"
                    max="9"
                    type="number"
                    class="input form-control form-control-lg"
                    v-model="modal.n1"
                    :disabled="loading"
                    role="presentation"
                    autocomplete="off"
                    placeholder=" "
                    maxlength="1"
                    required
                    id="n1"
                  />
                  <input
                    min="0"
                    max="9"
                    type="number"
                    class="input form-control form-control-lg"
                    v-model="modal.n2"
                    :disabled="loading"
                    role="presentation"
                    autocomplete="off"
                    placeholder=" "
                    maxlength="1"
                    required
                    id="n2"
                  />
                  <input
                    min="0"
                    max="9"
                    type="number"
                    class="input form-control form-control-lg"
                    v-model="modal.n3"
                    :disabled="loading"
                    role="presentation"
                    autocomplete="off"
                    placeholder=" "
                    maxlength="1"
                    required
                    id="n3"
                  />
                  <input
                    min="0"
                    max="9"
                    type="number"
                    class="input form-control form-control-lg"
                    v-model="modal.n4"
                    :disabled="loading"
                    role="presentation"
                    autocomplete="off"
                    placeholder=" "
                    maxlength="1"
                    required
                    id="n4"
                  />
                </div>
              </div>

              <div class="mt-4 d-flex justify-content-center">
                <button
                  type="submit"
                  class="btn btn-primary btn-lg submit-loader"
                  :disabled="loading"
                  tabindex="0"
                  for="submitted"
                >
                  Submit
                </button>
              </div>
            </form>

            <div class="text-center">
              <vue-countdown
                v-if="counting"
                :time="90 * 1000"
                @end="resendCountEnd"
                v-slot="{ totalSeconds }"
                >Resend code({{ totalSeconds }}s)</vue-countdown
              >
              <span v-else>
                <button class="btn" :disabled="loading" @click="resendCode()">
                  Click here to resend code.
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global bootstrap */
import Nprogress from 'nprogress'
import BaseAlert from '../components/BaseAlert.vue'
import { createToast } from 'mosha-vue-toastify'

Nprogress.configure({
  parent: '.nprogress-container',
  trickleSpeed: 50,
  showSpinner: true,
})

export default {
  components: { BaseAlert },
  name: 'login',
  props: {
    logo: {
      type: String,
      default: '/img/brand/green.png',
      description: 'Sidebar app logo',
    },
  },

  data() {
    return {
      email: '',
      password: '',
      error: null,
      loading: false,
      passwordType: 'password',
      resetPin: false,
      disabled: false,
      modal: { seconds_to_expire: 0, countdown: 'on' },
      counting: false,
      authyCode: null,
    }
  },

  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')
      return token
    },
    async login() {
      if (!this.loading) {
        const authyCode = this.authyCode
        this.loading = true
        this.error = null
        Nprogress.start()

        const rcToken = await this.recaptcha()

        this.$store
          .dispatch('login', {
            strategy: 'local',
            email: this.email,
            password: this.password,
            rcToken,
            authyCode,
          })
          .then((res) => {
            /**
             *
             * handle 2fa modal
             *
             */
            this.loading = false
            Nprogress.done()

            if (res && res.carrier) {
              this.modal.countdown = 'on'
              this.modal.seconds_to_expire = res.seconds_to_expire
              this.modal.message = res.message
              this.authyModal.show()
              this.pinCodeUI()
              this.counting = true
              setTimeout(() => {
                document.getElementById('n1').focus()
              }, 1000)
            }
            //
            else {
              setTimeout(() => {
                this.$router.push('/')
                this.authyModa.hide()
              }, 420)
            }
          })
          .catch((err) => {
            console.log('ERROR AT LOGIN: ', err)
            if (err.data) {
              setTimeout(() => {
                this.loading = false
                // this.error = err.data.message
                this.toast(err.data.message, {
                  type: 'danger',
                  position: 'bottom-right',
                  timeout: 5000,
                })
                Nprogress.done()
              }, 420)
            }
          })
      }
    },
    pinCodeUI() {
      let input = document.querySelectorAll('.input')

      //main loop
      for (let i = 0; i < input.length; i++) {
        input[i].addEventListener('click', () => {
          input[i].select()
        })

        input[i].addEventListener('blue', () => {
          if (input[input.length - 1].value) {
            input[input.length - 1].value = input[input.length - 1].value
              .split('')
              .pop()
          }
        })

        input[i].addEventListener('input', function () {
          if (input[i].value.length > 0) {
            if (input[i + 1]) {
              input[i + 1].focus()
              input[i + 1].select()
            }
          }
        })
        input[i].addEventListener('keydown', function (e) {
          this.value = this.value.replace(/[^0-9]/g, '')
          let key = e.which || e.keyCode || 0
          if (key === 8) {
            e.preventDefault()
            this.select()
            if (i - 1 < 0) {
              return
            } else {
              input[i - 1].focus()
              input[i - 1].select()
            }
          }
        })
      }
    },
    toast(msg, options) {
      return createToast(msg, options)
    },
    async validateCode(event) {
      if (event) {
        event.preventDefault()
      }

      Nprogress.start()
      this.disabled = true
      this.authyCode = `${this.modal.n1}${this.modal.n2}${this.modal.n3}${this.modal.n4}`
      await this.login()
    },
    resendCountEnd() {
      this.counting = false
    },
    resendCode() {
      this.authyCode = null
      this.login()
    },
  },
  mounted() {
    Nprogress.done()
    this.authyModal = new bootstrap.Modal('#authyModal')
  },
}
</script>
<style></style>
