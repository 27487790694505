import axios from 'axios'
const apiURL = process.env.VUE_APP_API_URL

export default {
  state: {
    merchant: null,
    merchants: [],
  },
  mutations: {
    SET_MERCHANT(state, data) {
      state.merchant = data
    },
    SET_MERCHANTS(state, data) {
      state.merchants = data
    },
  },
  actions: {
    async getMerchants({ commit }, options) {
      return await axios
        .get(
          `${apiURL}/merchants?$limit=${
            options.limit ? options.limit : 10
          }&$sort[id]=${options.sort ? options.sort : -1}&$skip=${
            options.skip ? options.skip : 0
          }`
        )
        .then((merchants) => commit('SET_MERCHANTS', merchants.data))
    },
    async getMerchant(args, options) {
      return await axios
        .get(`${apiURL}/merchants/${options.id}`)
        .then((merchant) => {
          return merchant.data
        })
    },
    async updateMerchant({ commit }, options) {
      return await axios
        .put(`${apiURL}/merchants/${options.id}`, {
          status: options.status,
          name: options.name,
          accountId: options.user.id,
        })
        .then((merchant) => commit('SET_MERCHANT', merchant.data))
    },
    async createMerchant({ commit }, options) {
      return await axios
        .post(`${apiURL}/merchants`, options)
        .then((merchant) => commit('SET_MERCHANT', merchant.data))
    },
  },
  getters: {
    merchant(state) {
      try {
        return state.merchant
      } catch (e) {
        console.log('Error getting merchant state', e)
      }
    },
    merchants(state) {
      try {
        return state.merchants
      } catch (e) {
        console.log('Error getting merchants', e)
      }
    },
  },
}
