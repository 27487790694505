import { createRouter, createWebHistory } from 'vue-router'
import store from '../vuex/store'
import axios from 'axios'

import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'

import Dashboard from '../views/Dashboard/Dashboard.vue'
// accounts
import Accounts from '../views/Accounts/Accounts.vue'
import AccountShow from '../views/Accounts/AccountShow.vue'
import AccountsList from '../views/Accounts/AccountsList.vue'
// submerchants
import Submerchants from '../views/Submerchants/Submerchants.vue'
import SubmerchantShow from '../views/Submerchants/SubmerchantShow.vue'
import SubmerchantsList from '../views/Submerchants/SubmerchantsList.vue'
// subusers
import Subusers from '../views/Subusers/Subusers.vue'
import SubuserShow from '../views/Subusers/SubuserShow.vue'
import SubusersList from '../views/Subusers/SubusersList.vue'
// deposits
// import Deposits from '../views/Deposits/Deposit.vue'
// import DepositShow from '../views/Deposits/DepositShow.vue'
// import DepositsList from '../views/Deposits/DepositsList.vue'
// wallets
// import Wallets from '../views/Wallets/Wallet.vue'
// import WalletShow from '../views/Wallets/WalletShow.vue'
// import WalletsList from '../views/Wallets/WalletsList.vue'
// addresses
// import Addresses from '../views/Addresses/Address.vue'
// import AddressShow from '../views/Addresses/AddressShow.vue'
// import AddressesList from '../views/Addresses/AddressesList.vue'
// withdrawals
// import Withdrawals from '../views/Withdrawals/Withdrawal.vue'
// import WithdrawalShow from '../views/Withdrawals/WithdrawalShow.vue'
// import WithdrawalsList from '../views/Withdrawals/WithdrawalsList.vue'

// Margin
import Margin from '../views/Margin/Margin.vue'
import Branches from '../views/Branches/Branches.vue'
import BranchesList from '../views/Branches/BranchesList.vue'
import BranchShow from '../views/Branches/BranchShow.vue'
import Merchants from '../views/Merchants/Merchants.vue'
import MerchantsList from '../views/Merchants/MerchantsList.vue'
import MerchantShow from '../views/Merchants/MerchantShow.vue'

import Profile from '../views/Profile/Profile.vue'
import Credentials from '../views/Credentials/Credentials.vue'
import CredentialShow from '../views/Credentials/CredentialShow.vue'
import CredentialsList from '../views/Credentials/CredentialsList.vue'
import NotFound from '../views/404.vue'

import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: DashboardLayout,
    children: [
      {
        path: `/dashboard`,
        name: 'dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: `/accounts`,
        name: 'accounts',
        component: Accounts,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ``,
            name: 'listAccounts',
            component: AccountsList,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: `:id`,
            name: 'showAccount',
            component: AccountShow,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: `/submerchants`,
        name: 'submerchants',
        component: Submerchants,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ``,
            name: 'listSubmerchants',
            component: SubmerchantsList,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: `:id`,
            name: 'showSubmerchant',
            component: SubmerchantShow,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: `/subusers`,
        name: 'subusers',
        component: Subusers,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ``,
            name: 'listSubusers',
            component: SubusersList,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: `:id`,
            name: 'showSubuser',
            component: SubuserShow,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      // {
      //   path: `/deposits`,
      //   name: 'deposits',
      //   component: Deposits,
      //   meta: {
      //     requiresAuth: true,
      //   },
      //   children: [
      //     {
      //       path: ``,
      //       name: 'listDeposits',
      //       component: DepositsList,
      //       meta: {
      //         requiresAuth: true,
      //       },
      //     },
      //     {
      //       path: `:id`,
      //       name: 'showDeposit',
      //       component: DepositShow,
      //       meta: {
      //         requiresAuth: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: `/wallets`,
      //   name: 'wallets',
      //   component: Wallets,
      //   meta: {
      //     requiresAuth: true,
      //   },
      //   children: [
      //     {
      //       path: ``,
      //       name: 'listWallets',
      //       component: WalletsList,
      //       meta: {
      //         requiresAuth: true,
      //       },
      //     },
      //     {
      //       path: `:id`,
      //       name: 'showWallet',
      //       component: WalletShow,
      //       meta: {
      //         requiresAuth: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: `/addresses`,
      //   name: 'addresses',
      //   component: Addresses,
      //   meta: {
      //     requiresAuth: true,
      //   },
      //   children: [
      //     {
      //       path: ``,
      //       name: 'listAddresses',
      //       component: AddressesList,
      //       meta: {
      //         requiresAuth: true,
      //       },
      //     },
      //     {
      //       path: `:id`,
      //       name: 'showAddress',
      //       component: AddressShow,
      //       meta: {
      //         requiresAuth: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: `/withdrawals`,
      //   name: 'withdrawals',
      //   component: Withdrawals,
      //   meta: {
      //     requiresAuth: true,
      //   },
      //   children: [
      //     {
      //       path: ``,
      //       name: 'listWithdrawals',
      //       component: WithdrawalsList,
      //       meta: {
      //         requiresAuth: true,
      //       },
      //     },
      //     {
      //       path: `:id`,
      //       name: 'showWithdrawal',
      //       component: WithdrawalShow,
      //       meta: {
      //         requiresAuth: true,
      //       },
      //     },
      //   ],
      // },

      {
        path: `/profile`,
        name: 'profile',
        components: { default: Profile },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: `/margin`,
        name: 'margin',
        components: { default: Margin },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: `/branches`,
        name: 'branches',
        components: { default: Branches },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            component: BranchesList,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ':id',
            component: BranchShow,
            name: 'showBranch',
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: `/merchants`,
        name: 'merchants',
        components: { default: Merchants },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            component: MerchantsList,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ':id',
            component: MerchantShow,
            name: 'showMerchant',
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: `/credentials`,
        name: 'credentials',
        components: { default: Credentials },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            component: CredentialsList,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'credentials/:id',
            name: 'showCredentials',
            component: CredentialShow,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        components: { default: Login },
      },
    ],
  },
  {
    path: '/',
    redirect: 'signup',
    component: AuthLayout,
    children: [
      {
        path: '/signup',
        name: 'signup',
        components: { default: Signup },
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: AuthLayout,
    // redirect: 'login',
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '404',
    component: AuthLayout,
    children: [
      {
        path: '/404',
        name: 'notfound',
        components: { default: NotFound },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes,
})

router.beforeEach(async (to, from, next) => {
  try {
    if (to.fullPath === '/logout') {
      console.log('LOGOUT')
      return store.dispatch('logout').then(() => {
        return next({
          name: 'login',
        })
      })
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      /**
       *
       * Check account role
       *
       */
      let account = localStorage.mb_portal_account
      if (account) {
        try {
          account = JSON.parse(account)
          const role = account.permissions[0]
          let allowedRole = true

          if (role === 'user') {
            if (to.fullPath === '/dashboard') {
              allowedRole = false
            }
          }

          if (!allowedRole) {
            return next({
              path: '/credentials',
            })
          }
        } catch (error) {
          console.log({ error })
          return next({
            name: 'login',
            params: { nextUrl: to.fullPath },
          })
        }
      }

      /**
       *
       * Check access token
       *
       */
      console.log('Validating token...')
      const accessToken = localStorage.getItem('mb_portal_accessToken')
      if (!accessToken) {
        console.log('Invalid token.')
        return next({
          name: 'login',
          params: { nextUrl: to.fullPath },
        })
      } else {
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
        return next()
      }
    } else {
      next()
    }
  } catch (e) {
    console.log('ERROR AT ROUTER CATCH: ', e)
  }
})

export default router
