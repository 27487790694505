<template>
  <card shadow type="secondary" class="nprogress-container">
    <template v-slot:header>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-8">
            <h3 class="mb-0">Settings</h3>
          </div>
        </div>
      </div>
    </template>

    <form role="form" v-if="this.model" name="form" @submit.prevent="update">
      <h6 class="heading-small text-muted mb-4">User information</h6>
      <div class="pl-lg-4 row">
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Company name"
            placeholder="Feelgood, Inc."
            input-classes="form-control-alternative"
            v-model="model.name"
            disabled
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Email address"
            placeholder="hello@example.com"
            input-classes="form-control-alternative"
            v-model="model.email"
            disabled
          />
        </div>
        <div class="col-lg-6">
          <div class="form-group has-label">
            <label class="form-control-label">Status</label>
            <div class="" label="Company name">
              <label
                class="custom-toggle m-0 mt-2"
                style="vertical-align: -5px"
              >
                <input
                  type="checkbox"
                  id="active"
                  v-model="model.active"
                  name="active"
                  class="d-block opacity-0"
                  disabled
                />
                <span class="custom-toggle-slider rounded-circle"></span>
              </label>
              <label class="m-0 ml-2" for="active">Active</label>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Mobile"
            placeholder="00000000"
            input-classes="form-control-alternative"
            v-model="model.mobile"
            type="number"
          />
        </div>

        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Old Password"
            placeholder="*******************"
            input-classes="form-control-alternative"
            v-model="model.oldPassword"
            type="password"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="New Password"
            placeholder="*******************"
            input-classes="form-control-alternative"
            v-model="model.password"
            type="password"
          />
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <button type="submit" class="btn btn-warning" :disabled="loading">
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Nprogress from 'nprogress'
import { createToast } from 'mosha-vue-toastify'

const progress1 = Nprogress.configure({
  parent: '.nprogress-container',
  trickleSpeed: 50,
  showSpinner: true,
})

export default {
  name: 'settings',
  data() {
    return {
      loading: false,
      moment,
    }
  },
  computed: {
    ...mapGetters(['account']),
    model: function () {
      return this.account
    },
  },
  methods: {
    toast(msg, options) {
      return createToast(msg, options)
    },
    async update() {
      progress1.start()
      this.message = null
      this.loading = true
      return await this.$store
        .dispatch('updateMyAccount', { ...this.model })
        .then(() => {
          setTimeout(async () => {
            this.loading = false
            this.toast('Successfully updated.', {
              type: 'success',
              position: 'bottom-right',
              timeout: 5000,
            })
            progress1.done()
          }, 420)
        })
        .catch((e) => {
          /**
           *
           * uncaught exceptions
           *
           */
          this.loading = false
          progress1.done()
          console.log('UNCAUGHT PROMISE: ', e)
          const message =
            e.data.message === 'jwt expired'
              ? 'Your session has expired. Login to continue.'
              : e.data.message
          this.toast(message, {
            type: 'danger',
            position: 'bottom-right',
            timeout: 5000,
          })
        })
    },
  },
  async mounted() {},
}
</script>
<style></style>
